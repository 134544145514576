// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  platform: 'mobile',
  api: {
    url: 'https://api.dev.bonnie.co.uk',
    client_id: 2,
    client_secret: 'jXP9ifPslxRHYSkl9coqQtZANA13eaU6C7I4Ivwd',
    version: 'v1',
    salon_id: null,
  },
  inEnvFile: true,
  settings: {
    salon: {},
    deposit: {
      salon_deposit_type: 'percentage',
      salon_deposit_amount: 50,
      salon_takes_deposit: 'yes'
    }, 
    availability: {
      available_website: true,
      available_mobile_app: true,
    },
    app: {
      logo: 'assets/Bonnie-CREAM.svg',
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
