import { FcmService } from './services/fcm/fcm.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CartService } from './services/cart/cart.service';
import { SettingsService } from './services/settings/settings.service';
import { Component, OnChanges } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Category } from './interfaces/category';
import { CategoriesService } from './services/categories/categories.service';

const CART_EXTERNAL_REFRESH_TIMEOUT = 30 * 1000 // 30 seconds;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnChanges {
  loading = true;
  loadingModal: HTMLIonLoadingElement;
  currentPlatform: string = '';
  cart_timeout;

  categories: Category[] = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private settings: SettingsService,
    private auth: AuthService,
    private fcm: FcmService,
    private categoriesService: CategoriesService,
    private cartService: CartService,
  ) {
    // set angular to always run ngOnInit when navigating to a page which has already been loaded in current session (e.g view-appointments)
    // this fixes the issue where, for example, new appointments dont show in view appointments when that page has been visited before a new booking was made
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    
    this.initializeApp();
  }

  ngOnChanges() {
    //
  }

  initializeApp() {
    // this.geolocation.getCurrentCoordinates().then((coords: Coordinates) => {
    //   this.geolocation.getAddress().then((address: string) => {
    //     console.log({address});
    //   }).catch((error: any) => {
    //     console.log('There was an error getting the address from the coordinates');
    //   })
    // }).catch((error: any) => {
    //   console.log('There was an error fetching the coordinates');
    // });

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.loadPlatform();
      this.registerCartRefresh();
      this.getCategories();
      this.splashScreen.hide();
    });
  }

  registerCartRefresh() {
    this.auth.loggedIn$.subscribe(isLoggedIn => {
      if (isLoggedIn === true) {
        this.cartService.getCartExternal();

        this.cart_timeout = setInterval(() => {
          this.cartService.getCartExternal();
        }, CART_EXTERNAL_REFRESH_TIMEOUT);
      } else {
        clearInterval(this.cart_timeout);
      }
    });
  }

  async loadPlatform() {
    this.currentPlatform = await this.settings.getPlatform();
  }

  async getCategories() {
    this.categories = await this.categoriesService.getCategories();
  }
}
